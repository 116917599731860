<!-- 设备管理——数据库驾驶舱——喷涂线 -->
<template>
    <div class="content_kb">
        <div class="board_bgd">
            <div class="pdt_20 pdb_10 flex justify_between align_center">
                <div class="fn_size22 color_00C6FF">
                    <span>{{equiStatusSprayName}}</span>
                    <span>：</span>
                    <span class="color_FFFC00">{{equiStatusSprayValue}}</span>
                </div>
                <div class="topTitle fn_size28 color_FFFFFF">
                    {{equiDashBoardSprayGetTitle}}
                </div>
                <div class="fn_size22 color_00C6FF flex align_center ">
                    {{currentTime}}
                    <img src="../../../assets/images/comprehensive/full.png" class="mg_left_20" alt="">
                </div>
            </div>
            <!-- <div>

            </div> -->

            <el-row>
                <el-col :span="6">
                    <el-row>
                        <el-col class="diy_col">
                            <div class="diy_div">
                                <div class="">工件信息</div>
                                <div class="element">
                                    <el-row class="myRow" v-for="(item,index) in equiWorkpieceinforSpray" :key="index"
                                       :class="index%2 ==0?'bgc_143765':'bgc_12487F'">
                                        <el-col :span="12">{{item.name}}</el-col>
                                        <el-col :span="12">{{item.value}}</el-col>
                                    </el-row>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col class="diy_col">
                            <div class="diy_div">
                                <div class="">节拍利用率</div>
                                <div id="echartsJPLY" style="width:100%;height: 100%;"></div>
                            </div>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="12" class="diy_col2">
                    <div class="diy_div3">
                        <div class="diy_div_title">设备布局</div>
                        <div class="flex justify_between mg_left_20">
                            <div class="flex ">
                                <div class="info_top_right">
                                    <div class="element3 flex flex_column">
                                        <div class="color_10DA4E fn_size18">5</div>
                                        <div class="element3_div">min/pcs</div>
                                    </div>
                                    <div class="color_10DA4E fn_size14 mg_top_6">生产节拍</div>
                                </div>
                                <div class="info_top_right">
                                    <div class="element4 flex flex_column">
                                        <div class="color_FDEC31 fn_size18">12.3</div>
                                        <div class="element4_div">kwh/pcs</div>
                                    </div>
                                    <div class="color_FDEC31 fn_size14 mg_top_6">单位耗能</div>
                                </div>
                            </div>
                            <div class="flex justify_end mg_right_30">
                                <div class=" flex flex_column align_start color_00C6FF ">
                                    <div class="  ">产能：1,354</div>
                                    <div class="  ">电能：48,789</div>
                                </div>
                            </div>
                        </div>
                        <div id="ecartsBJ" style="width:100%;height: 100%;">
                            <img src="../../../assets/images/comprehensive/middle-box2-content.png" class="" alt=""
                                style="width: 80%;height: 80%;">
                        </div>
                    </div>
                </el-col>
                <el-col :span="6">
                    <el-row>
                        <el-col class="diy_col">
                            <div class="diy_div">
                                <div class="">工艺参数</div>
                                <div class="element">
                                    <el-row class="myRow" v-for="(item,index) in equiCraftDataSpray" :key="index"
                                        :class="index%2 ==0?'bgc_12487F':'bgc_143765'">
                                        <el-col :span="6">{{item.id}}</el-col>
                                        <el-col :span="6">{{item.number}}</el-col>
                                        <el-col :span="6">{{item.name}}</el-col>
                                        <el-col :span="6">{{item.value}}</el-col>
                                    </el-row>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col class="diy_col">
                            <div class="diy_div">
                                <div class="">环境数据</div>
                                <div class="element">
                                    <el-row class="myRow" v-for="(item,index) in equiEnvironmentData" :key="index"
                                         :class="index%2 ==0?'bgc_143765':'bgc_12487F'">
                                        <el-col :span="7">{{item.id}}</el-col>
                                        <el-col :span="10">{{item.name}}</el-col>
                                        <el-col :span="7">{{item.value}}</el-col>
                                    </el-row>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="6" class="diy_col">
                    <div class="diy_div">
                        <div class="">故障率/MTBF</div>
                        <div id="echartsGZL" style="width:100%;height: 100%;"></div>
                    </div>
                </el-col>
                <el-col :span="12" class="diy_col">
                    <div class="diy_div2">
                        <div class="">关键趋势(抛丸机电压/抛丸机电流)</div>
                        <div id="ecartsGJQS" style="width:100%;height: 100%;"></div>
                    </div>
                </el-col>
                <el-col :span="6" class="diy_col">
                    <div class="diy_div">
                        <div class="">报警信息</div>
                        <div class="element">
                            <el-row>
                                <el-col :span="4">序号</el-col>
                                <el-col :span="4">代码</el-col>
                                <el-col :span="7">时间</el-col>
                                <el-col :span="4">描述</el-col>
                                <el-col :span="4">时长</el-col>
                            </el-row>
                            <el-row class="myRow" v-for="(item,index) in equiAralmDataSpray" :key="index"
                                :class="index%2 ==0?'bgc_12487F':'bgc_143765'">
                                <el-col :span="4">{{index+1}}</el-col>
                                <el-col :span="4">{{item.code}}</el-col>
                                <el-col :span="7">{{item.time}}</el-col>
                                <el-col :span="4" class="col_overflow">{{item.value}}</el-col>
                                <el-col :span="4">{{item.timeLength}}</el-col>
                            </el-row>
                        </div>
                    </div>
                </el-col>
            </el-row>

        </div>
    </div>

</template>
<script>
    // import innerTopNav from './../../components/innerTopNav'
    import * as echarts from 'echarts';
    import {
        equipmentDashBoardSprayGetTitle, //获取看板标题
        organizationSelectSpray, //组织架构选择
        equipmentStatusSpray, //设备状态
        equipmentWorkpieceinforSpray, //工件信息
        equipmentCraftDataSpray, //工艺数据
        equipmentAralmDataSpray, //报警记录
        equipmentEnvironmentData, //环境数据
        equipmentUtilization, //节拍利用率
        equipmentFaultRate, //故障率
        equipmentPropertyTrendSpray, //关键趋势
        // equipmentTrendSpray,//设备属性趋势
        // equipmentLayoutDataSpray,//设备布局
    } from "@/api/api_deviceManagement";

    export default {
        // components: {
        //     innerTopNav
        // },
        data() {
            return {
                currentTime: new Date(),
                equiDashBoardSprayGetTitle: '', //看板标题
                equiStatusSprayValue: '', //设备状态
                equiStatusSprayName: '', //设备状态
                equiWorkpieceinforSpray: [], //工件信息
                equiCraftDataSpray: [], //工艺数据
                equiEnvironmentData: [], //环境数据
                equiAralmDataSpray: [], //报警记录
                dataJPLY: [], //节拍利用率
                dataJPLYValue: [], //节拍利用率value
                dataGZLMTBF: [], //故障率-MTBF
                dataGZLFaultRateValue: [], //故障率
                dataGZLName: [], //故障率-设备名称
                equiPropertyTrendSprayX: [],//数据趋势-X轴数据
                equiPropertyTrendSprayDY: [],//数据趋势-抛丸机电压
                equiPropertyTrendSprayDL: [],//数据趋势-抛丸机电流
            }
        },
        mounted() {
            if (localStorage.getItem('permission') != null) {
                // console.log(JSON.parse(localStorage.getItem('permission')));
                this.permission = JSON.parse(localStorage.getItem('permission'))
            }
            this.currentTime = this.tools.formatDate(this.currentTime, 'yyyy-MM-dd hh:mm:ss')
            this.drawShap();
            this.equipmentDashBoardSprayGetTitle(); //获取看板标题
            this.organizationSelectSpray(); //组织架构选择
            this.equipmentStatusSpray(); //设备状态
            this.equipmentWorkpieceinforSpray(); //工件信息
            this.equipmentCraftDataSpray(); //工艺数据
            this.equipmentAralmDataSpray(); //报警记录
            this.equipmentEnvironmentData(); //环境数据
            this.equipmentUtilization(); //节拍利用率
            this.equipmentFaultRate(); //故障率
            this.equipmentPropertyTrendSpray(); //关键趋势
            // this.equipmentTrendSpray(); //
            // this.equipmentLayoutDataSpray(); //
        },
        methods: {

            //接口-开始-------------------
            //1、获取看板标题
            async equipmentDashBoardSprayGetTitle() {
                var param = {
                    equipmentID: 100001,
                }
                const selectRes = await equipmentDashBoardSprayGetTitle(param);
                this.equiDashBoardSprayGetTitle = selectRes.data;
                // console.log(selectRes.data)
            },
            //2、组织架构选择
            async organizationSelectSpray() {
                var param = {
                    permission: this.permission,
                }
                const selectRes = await organizationSelectSpray(param);
                // this.equiDashBoardGetTitle = selectRes.data;
                // console.log(selectRes.data)
            },
            //3、设备状态
            async equipmentStatusSpray() {
                var param = {
                    equipmentID: 100001,
                }
                const selectRes = await equipmentStatusSpray(param);
                this.equiStatusSprayName = selectRes.data.name;
                this.equiStatusSprayValue = selectRes.data.value;
                // console.log(selectRes.data)
            },
            //4、工件信息
            async equipmentWorkpieceinforSpray() {
                var param = {
                    equipmentID: 100001,
                }
                const selectRes = await equipmentWorkpieceinforSpray(param);
                this.equiWorkpieceinforSpray = selectRes.data;
                // console.log(selectRes.data)
            },
            //5、工艺数据
            async equipmentCraftDataSpray() {
                var param = {
                    equipmentID: 100001,
                }
                const selectRes = await equipmentCraftDataSpray(param);
                this.equiCraftDataSpray = selectRes.data;
                // console.log(selectRes.data)
            },
            //6、环境数据
            async equipmentEnvironmentData() {
                var param = {
                    equipmentID: 100001,
                }
                const selectRes = await equipmentEnvironmentData(param);
                this.equiEnvironmentData = selectRes.data;
                // console.log(selectRes.data)
            },
            //7、报警分析
            async equipmentAralmDataSpray() {
                var param = {
                    equipmentID: 100001,
                }
                const selectRes = await equipmentAralmDataSpray(param);
                this.equiAralmDataSpray = selectRes.data;
                // console.log(selectRes.data)
            },
            //8、节拍利用率
            async equipmentUtilization() {
                var param = {
                    equipmentID: 100001,
                }
                const selectRes = await equipmentUtilization(param);
                for (var i = 0; i < selectRes.data.length; i++) {
                    var temp = {}
                    temp.name = selectRes.data[i].name;
                    // temp.max = selectRes.data[i].value;
                    temp.max = 100;
                    this.dataJPLY.push(temp)
                    this.dataJPLYValue[i] = selectRes.data[i].value;
                }
                // console.log(this.dataJPLYValue)
                this.drawJPLY(this.dataJPLY, this.dataJPLYValue);
            },
            //9、故障率
            async equipmentFaultRate() {
                var param = {
                    equipmentID: 100001,
                }
                const selectRes = await equipmentFaultRate(param);
                for (var i = 0; i < selectRes.data.length; i++) {
                    this.dataGZLName[i] = selectRes.data[i].name;
                    this.dataGZLMTBF[i] = selectRes.data[i].MTBF;
                    this.dataGZLFaultRateValue[i] = selectRes.data[i].faultRateValue.split("%").join("");
                    // console.log(selectRes.data[i].faultRateValue.split("%").join(""))
                }
                // console.log(this.dataJPLYValue)
                this.drawGZL(this.dataGZLName, this.dataGZLMTBF, this.dataGZLFaultRateValue);
            },
            //10、关键趋势
            async equipmentPropertyTrendSpray() {
                var param = {
                    equipmentID: 100001,
                }
                const selectRes = await equipmentPropertyTrendSpray(param);
                this.equiPropertyTrendSprayX = selectRes.data[0].value;//x轴数据
                this.equiPropertyTrendSprayDY = selectRes.data[1].value;//抛丸机电压
                this.equiPropertyTrendSprayDL = selectRes.data[2].value;//抛丸机电流
                // console.log(selectRes.data)
                this.drawShap(this.equiPropertyTrendSprayX,this.equiPropertyTrendSprayDY,this.equiPropertyTrendSprayDL);
            },
            //11、
            // async equipmentTrendSpray() {
            //     var param = {
            //         equipmentID: 100001,
            //     }
            //     const selectRes = await equipmentTrendSpray(param);
                // this.equiDashBoardSprayGetTitle = selectRes.data;
                // console.log(selectRes.data)
            // },
            // 12、
            // async equipmentLayoutDataSpray() {
            //     var param = {
            //         equipmentID: 100001,
            //     }
            //     const selectRes = await equipmentLayoutDataSpray(param);
                // this.equiDashBoardSprayGetTitle = selectRes.data;
                // console.log(selectRes.data)
            // },
            //接口-结束-------------------

            drawGZL(dataGZLName, dataGZLMTBF, dataGZLFaultRateValue) {
                //故障率
                this.echartsGZL = echarts.init(document.getElementById('echartsGZL'));
                this.echartsGZL.setOption({
                    color: ['#1BD260', '#F8AF21'],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            crossStyle: {
                                color: '#999'
                            }
                        }
                    },
                    legend: {
                        data: ['MTBF', '故障率'],
                        bottom: '5%',
                        itemHeight: 10, //修改icon图形大小
                        textStyle: {
                            color: '#FFFFFF',
                            fontSize: 12,
                        }
                    },
                    grid: {
                        left: '5%',
                        right: '5%',
                        bottom: '20%',
                        top: '8%',
                        containLabel: true,
                        borderWidth: 1,
                        borderColor: '#FFFFFF'
                    },
                    xAxis: [{
                        type: 'category',
                        data: dataGZLName,
                        axisPointer: {
                            type: 'shadow'
                        },
                        axisLabel: {
                            interval: 0,
                            color: '#FFFFFF',
                            fontSize: 12,
                            rotate: 0,
                        },
                    }],
                    yAxis: [{
                            type: 'value',
                            // name: '水量',
                            min: 0,
                            max: 100,
                            interval: 20,
                            axisLabel: {
                                color: '#FFFFFF',
                                fontSize: 12,
                                // formatter: '{value} ml'
                            },
                        },
                        {
                            type: 'value',
                            min: 0,
                            max: 100,
                            interval: 20,
                            axisLabel: {
                                color: '#FFFFFF',
                                fontSize: 12,
                                formatter: '{value} %'
                            },
                        }
                    ],
                    series: [{
                            name: 'MTBF',
                            type: 'bar',
                            data: dataGZLMTBF
                        },
                        {
                            name: '故障率',
                            type: 'line',
                            yAxisIndex: 1,
                            data: dataGZLFaultRateValue
                        }
                    ]
                })
            },

            drawShap(trendX,trendDY,trendDL) {

                //关键趋势
                this.ecartsGJQS = echarts.init(document.getElementById('ecartsGJQS'));
                this.ecartsGJQS.setOption({
                    tooltip: {
                        trigger: 'axis'
                    },
                    legend: {
                        data: ['抛丸机电压', '抛丸机电流'],
                        bottom: '5%',
                        textStyle: {
                            color: '#ffffff',
                            opacity: 0.5,
                        }
                    },
                    grid: {
                        left: '5%',
                        right: '5%',
                        bottom: '20%',
                        top: '8%',
                        containLabel: true,
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: true,
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLabel: {
                            interval: 0,
                            color: '#FFFFFF',
                            fontSize: 12,
                            rotate: 0,
                        },
                        data: trendX
                    },
                    yAxis: {
                        type: 'value',
                        min: 0,
                        max: 100,
                        interval: 10,
                        axisLabel: {
                            color: '#FFFFFF',
                            fontSize: 12,
                            // formatter: '{value} ml'
                        },
                        splitLine: { //分割线配置
                            show: true,
                            lineStyle: {
                                color: "#FFFFFF",
                                opacity: 0.2
                            }
                        }
                    },
                    series: [{
                            name: '抛丸机电压',
                            type: 'line',
                            // stack: '总量',
                            color: '#00A8FF',
                            data: trendDY
                        },
                        {
                            name: '抛丸机电流',
                            type: 'line',
                            // stack: '总量',
                            color: '#6C7BE8',
                            data: trendDL
                        }
                    ]

                })
            },

            drawJPLY(dataJPLY, dataJPLYValue) {

                //节拍利用率
                this.echartsJPLY = echarts.init(document.getElementById('echartsJPLY'));
                this.echartsJPLY.setOption({
                    // label: {
                    //     show: true,
                    // },
                    tooltip: {
                        show: true,
                    },
                    color: ['#00FF00'],
                    legend: {
                        data: ['节拍利用率'],
                        bottom: '5%',
                        itemHeight: 4, //修改icon图形大小
                        itemWidth: 20,
                        // icon: 'pin',
                        textStyle: {
                            color: '#FFFFFF',
                            fontSize: 12,
                        }
                    },
                    radar: {
                        // shape: 'circle',
                        radius: 50,
                        shape: 'circle',
                        center: ['50%', '45%'],
                        indicator: dataJPLY,
                    },
                    series: [{
                        name: '节拍利用率',
                        type: 'radar',
                        data: [{
                            value: dataJPLYValue,
                            name: '预算分配',
                            areaStyle: {
                                color: '#00FF00'
                            }
                        }, ]
                    }]
                })

            },

            formatDate(row, column) {
                // 获取单元格数据
                let data = row[column.property]
                if (data == undefined) {
                    return ''
                };
                return this.tools.formatDate(data, 'yyyy-MM-dd hh:mm:ss');
            },
        }

    }
</script>
<style scoped>
    .content_kb {
        background-color: #151B32;
        height: 100%;
    }

    .board_bgd {
        width: 100vw;
        height: 86vh;
        background-image: url('../../../assets/images/comprehensive/bgimg.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        /* padding-bottom: 1px; */
        /* display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: Source Han Sans SC; */
        padding: 0 20px;
    }

    .topTitle {
        width: 634px;
        height: 50px;
        background-image: url('../../../assets/images/comprehensive/panel-bgimg.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        /* fn_size28 color_FFFFFF */
        font-size: 28px;
        color: #FFFFFF;
        font-weight: bold;
    }

    .diy_col {
        height: 25vh;
        color: #FFFFFF;
        font-size: 14px;
        /* margin-bottom: 10px; */
    }

    .diy_col2 {
        height: 50vh;
        color: #FFFFFF;
        font-size: 14px;
        /* margin-bottom: 10px; */
    }

    .diy_div {
        width: 100%;
        height: 100%;
        background-image: url('../../../assets/images/comprehensive/left-box1.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding-top: 8px;
        display: flex;
        flex-direction: column;
        /* align-items: center; */
    }

    .diy_div2 {
        width: 100%;
        height: 100%;
        background-image: url('../../../assets/images/comprehensive/middle-box1.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding-top: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .diy_div3 {
        width: 100%;
        height: 100%;
        background-image: url('../../../assets/images/comprehensive/middle-box2.png');
        background-repeat: no-repeat;
        background-size: 99% 98%;
        padding-top: 3px;
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        margin-left: 5px;
        margin-top: 5px;
    }

    .diy_div_title {
        padding-bottom: 3px;
    }

    .info_top_right {
        display: flex;
        flex-direction: column;
        margin-right: 10px;
    }

    .element3 {
        width: 80px;
        height: 80px;
        background: transparent;
        border-radius: 50%;
        border: 8px solid #10DA4E;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .element4 {
        width: 80px;
        height: 80px;
        background: transparent;
        border-radius: 50%;
        border: 8px solid #FFFD54;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .element3_div {
        font-size: 14px;
        color: #10DA4E;
        /* border-bottom: 1px #10DA4E solid; */
        border-top: 1px #10DA4E solid;
        padding-bottom: 2px;
        text-align: center;
    }

    .element4_div {
        font-size: 14px;
        color: #FFFD54;
        /* border-bottom: 1px #FFFD54 solid; */
        border-top: 1px #FFFD54 solid;
        padding-bottom: 2px;
        text-align: center;
    }

    /**取消滚动条--开始 */
    .element {
        width: 90%;
        height: 78%;
        margin-top: 2px;
        overflow: auto;
        margin-left: 24px;
        margin-right: 24px;
        font-size: 12px;
        -ms-overflow-style: none;
        /**IE 10+ */
        overflow: -moz-scrollbars-none;
        /**Firefox */
    }

    /** chrome 和Safari*/
    .element::-webkit-scrollbar {
        width: 0 !important
    }

    /**取消滚动条--结束 */

    /**表格加边框 --开始*/
    .element .el-row:first-child {
        background-color: #143765;
        /* border-top: 1px #ffffff solid; */
    }

    .element .el-row {
        /* border-bottom: 1px #ffffff solid;
    border-left: 1px #ffffff solid; */
        color: #FFFFFF;
        display: flex;
        flex-wrap: wrap
    }

    .element .el-row .el-col {
        height: 100%;
        /* border-right: 1px #ffffff solid; */
        padding: 10px 0px;
    }

    .element .myRow:hover>.el-col {
        background-color: rgba(255, 255, 255, 0.2) !important;
        /* color:#73f3ff; */
    }
</style>